<script setup lang="ts">
import GlobalOnlineUsersButton from "~/components/global/GlobalOnlineUsersButton.vue";

const user = useSupabaseUser()
const route = useRoute()
const toast = useToast()

const userStore = useUserStore()

const links = computed(() => {
	const list = [{ label: 'Dashboard', icon: 'i-heroicons-chart-pie', to: '/' }]
	if (user.value) {
		if (userStore.user.role === 'planner' || userStore.user.role === 'admin') {
			list.push({ label: 'Planner Tools', icon: 'i-fa6-solid-screwdriver-wrench', to: '/planners' })
		}
		list.push(
			{ label: 'Packages', icon: 'i-fa6-solid-box', to: '/packages' },
			// { label: 'IWPs', icon: 'i-fa6-solid-folder', to: '/iwp' },
			// { label: 'CWPs', icon: 'i-fa6-solid-folder-tree', to: '/cwp' },
			{ label: 'Data', icon: 'i-fa6-solid-database', children: [
					{ label: 'Qtrax', to: '/data/qtrax' },
					{ label: 'PCN', to: '/data/pcn' },
					{ label: 'Schedule', to: '/data/schedule' },
					{ label: 'Rates', to: '/data/rates' },
					{ label: 'Aconex', to: '/data/aconex' },
					{ label: 'Upload Data', to: '/data/upload' },
			],
				defaultOpen: route.path.startsWith('/data'),
			},
			{ label: 'Settings', icon: 'i-heroicons-cog', to: '/settings' },
		)
	} else {
		list.push({ label: 'Login', icon: 'i-heroicons-user-circle', to: '/login' })
	}
	return list
})


const logout = () => {
	useSupabaseClient().auth.signOut()
	useToast().add({title: 'Success', description: 'Logout successful', color: 'green', icon: 'i-heroicons-check-circle'})
	useRouter().push('/login')
}
</script>

<template>
	<UDashboardLayout>
		<UDashboardPanel :width="250" collapsible :resizable="{ min: 200, max: 400, storage: 'local' }"  >
			<UDashboardNavbar>
				<template #center>
					<h1 class="font-semibold text-xl text-center text-green-600 dark:text-green-400">
						Project Controls Reporting
					</h1>
				</template>
			</UDashboardNavbar>
			<UDashboardSidebar>
				<UDashboardSidebarLinks :level="2" :links="links" />
				<template #footer>
					<div class="flex flex-row w-full gap-2 items-center justify-center">
						<GlobalOnlineUsersButton />
						<UButton color="red" block icon="i-heroicons-arrow-left-start-on-rectangle" class="w-1/2" v-if="user" @click="logout">Logout</UButton>
					</div>
				</template>
			</UDashboardSidebar>
		</UDashboardPanel>
		<UDashboardPage>
			<UDashboardPanel grow>
				<UDashboardNavbar title="Project Controls Reporting">
					<template #left>
						<UDashboardSearch />
					</template>
					<template #right>
						<ClientOnly>
							<UColorModeSelect />
						</ClientOnly>
					</template>
				</UDashboardNavbar>
				<GlobalFileUploadAlert />
				<UDashboardPanelContent class="w-full mx-auto">
					<slot />
				</UDashboardPanelContent>
			</UDashboardPanel>
		</UDashboardPage>
	</UDashboardLayout>
	<UNotifications />
	<UModals />
</template>